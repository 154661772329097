@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700;800;900&display=swap);
/*Font*/

/*Global Styles*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Barlow";
}

textarea,
html {
  font-size: 10px;
  font-family: "Barlow", sans-serif;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}
.h-100 {
  height: 100%;
}
.full-container {
  width: 95%;
  padding: 0 3rem;
  margin: 0 auto;
  max-width: 130rem;
}

.small-container {
  width: 90%;
  padding: 0 3rem;
  margin: 0 auto;
  max-width: 100rem;
}
@media screen and (max-width: 990px) {
  .small-container {
    width: 90%;
  }
}
.screen {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  overflow: hidden;
}
.screen::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 32%;
  height: 100%;
  background-color: #de291d;
}
.screen::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 32%;
  height: 100%;
  background-color: rgba(222, 41, 29, 0.7);
}

@media screen and (max-width: 1330px) {
  .screen::after,
  .screen::before {
    width: 27rem;
  }
}

.screen-wrapper {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
}
.screen-img {
  transform: translateY(1rem);
}
.screen-img img {
  height: 60rem;
  object-fit: cover;
}
.screen-content {
  display: flex;
  flex-direction: column;
  width: 45%;
  align-self: center;
}
.h1-heading {
  font-weight: 800;
  /* text-transform: capitalize; */
  font-size: 4.5rem;
  margin-bottom: 2rem;
}
.p-text {
  font-size: 1.8rem;
  margin-bottom: 3rem;
}
.our-btn {
  width: 100%;
  background-color: #de291d;
  outline: none;
  border: 0;
  color: #fff;
  text-transform: capitalize;
  font-weight: 700;
  padding: 1.25rem;
  text-align: center;
  font-size: 2rem;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.our-btn:hover {
  background-color: #de291d;
  color: #fff;
}
.our-btn-outline {
  background-color: #fff;
  border: 2px solid #de291d;
  color: #de291d;
}
.our-btn-outline:hover {
  color: #fff;
}
.input-el {
  padding: 1rem;
  border: 1.25px solid #c6c6c6;
  margin-bottom: 1rem;
  width: 100%;
  font-size: 1.6rem;
  border-radius: 0.5rem;
}
textarea {
  font-size: 1.6rem;
}

.form {
  margin-bottom: 2rem;
}
.form-check {
  display: flex;
  align-items: center;
}
.form-check label {
  text-transform: capitalize;
  font-size: 1.6rem;
  color: #bcbcbc;
  padding-left: 1rem;
  margin: 0;
  transform: translateY(-0.15rem);
}
.hide {
  display: none;
}
.show {
  display: block;
}
/*Navbar*/
.navbar {
  position: relative;
  z-index: 10;
  padding: 2rem 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.logo img {
  height: 3rem;
}
.navbar-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 15rem;
}
.navbar__list {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navbar__list__reverse {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.navbar__item:not(:last-child) {
  margin-right: 3rem;
}
.navbar__link {
  font-size: 1.6rem;
  font-weight: 600;
  color: #000;
  text-transform: capitalize;
}
.navbar__btn {
  margin-left: 1rem;
  border: none;
  outline: none;
  background-color: #de291d;
  padding: 0.25rem 1rem;
  color: #fff;
  border-radius: 1rem;
  cursor: pointer;
  font-weight: 500;
  position: absolute;
  right: 7rem;
}
.menu-btn {
  width: 3rem;
  height: 2rem;
  cursor: pointer;
}
.menu-btn .line {
  width: 100%;
  height: 5px;
  background-color: #000;
  border-radius: 5px;
}
.menu-btn .line-2 {
  margin: 0.5rem 0;
}

@media screen and (max-width: 870px) {
  .navbar__list {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 30;
    transform: translateY(-100%);
    transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  .navbar__list__reverse {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 30;
    transform: translateY(-100%);
    transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  .navbar__list.show {
    transform: translateY(30%);
  }
  .navbar__list__reverse.show {
    transform: translateY(30%);
  }
  .navbar__item:not(:last-child) {
    margin: 0;
  }
  .navbar__item {
    padding: 1rem 0;
  }
  .navbar__btn {
    margin: 0;
    right: 10rem;
  }
  .menu-btn {
    display: block;
    position: absolute;
    right: 5rem;
  }
}

/*Features Page*/
.features {
  display: flex;
  flex-direction: column;
}
.features .h1-heading {
  text-align: center;
  font-size: 4rem;
  width: 100%;
}
.features__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  padding: 2rem 0;
  min-height: 25rem;
}
.features__list__reverse {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  padding: 2rem 0;
  min-height: 25rem;
}
.feature__item {
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem 0;
  border-radius: 1rem;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.feature__item:hover {
  background-color: #de291d;
}
.feature__item:hover h3 {
  color: #fff;
}
.feature__item img {
  height: 6rem;
}
.sign__check h3,
.sign__item h3,
.feature__el h3,
.feature__item h3 {
  text-transform: capitalize;
  font-weight: 400;
  font-size: 1.6rem;
  margin-top: 0.5rem;
}

.feature__el {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f4f4f4;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 2rem;
  position: relative;
  min-height: 25rem;
  max-height: 26rem;
  overflow-y: scroll;
}
.feature__el h3 {
  margin-bottom: 2rem;
  font-size: 1.7rem;
}
.feature__el button {
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  border: 2px solid #000;
  border-radius: 50%;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  top: 2rem;
  right: 2rem;
}

/*Contact*/
.contact {
  display: flex;
  flex-direction: column;
}
.contact__form-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}
.phone-input {
  display: flex;
  position: relative;
}
.phone-input img {
  height: 3.25rem;
}
.phone-input .country {
  position: absolute;
  top: 43%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  left: 6px;
}
.phone-input .input-el {
  padding-left: 9rem;
}
.phone-input span {
  font-size: 1.7rem;
  color: #000;
  padding: 0;
  font-weight: 500;
  margin: 0;
  transform: translateY(-0.1rem);
}
.sign__list {
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}
.sign__item {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
  padding: 1rem 0;
  border-radius: 1rem;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}
.sign__item__active {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #de291d;
  padding: 1rem 0;
  border-radius: 1rem;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  cursor: pointer;
}
.sign__item:hover {
  background-color: #de291d;
  cursor: pointer;
}

.sign__item:hover h3 {
  font-weight: 700;
  color: #fff;
}

.sign__item__h3__active {
  font-weight: 700;
  color: #fff;
  font-size: 1.6rem;
  margin-top: 0.5rem;
  text-transform: capitalize;
}

.sign__check {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.sign__check h3 {
  text-transform: capitalize;
  font-weight: 400;
  font-size: 1.6rem;
  margin-top: 0.5rem;
  margin: 0;
  margin-right: 2rem;
  padding: 0;
}
.form-radio {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.form-radio:not(:last-child) {
  margin-right: 1rem;
}
.form-radio label {
  font-size: 1.6rem;
  padding-left: 0.5rem;
}

@media screen and (max-width: 850px) {
  .screen-img img {
    height: 50rem;
  }
  .screen::after,
  .screen::before {
    width: 18rem;
  }
  .screen-content {
    width: 50%;
  }
  .small-container {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .screen {
    overflow: visible;
    overflow: initial;
    height: auto;
  }
  .screen::after,
  .screen::before {
    position: absolute;
    width: 100%;
    height: 10%;
  }
  .screen-wrapper {
    padding-top: 10rem;
    flex-direction: column;
  }
  .screen-content {
    width: 100%;
    text-align: center;
    z-index: 100;
    padding-bottom: 4rem;
  }
  .screen-img {
    width: 100%;
    margin-bottom: 4rem;
    text-align: center;
  }
  .screen-img img {
    height: 40rem;
  }
  .h1-heading {
    font-size: 4rem;
  }
  .our-btn {
    background-color: #fff;
    border: 2px solid #de291d;
    color: #de291d;
  }
  .navbar__btn {
    background-color: #fff;
    color: #de291d;
    right: 10rem;
  }
  .menu-btn .line {
    width: 100%;
    height: 5px;
    background-color: #fff;
    border-radius: 5px;
    /* position: relative; */
    /* margin-left: 15rem; */
  }
}
@media screen and (max-width: 500px) {
  html {
    font-size: 9px;
  }
}

